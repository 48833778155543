import { css } from "@emotion/react";
import { graphql } from "gatsby";
import React from "react";
import Card from "../components/card";
import Carousel from "../components/carousel";
import ImageLink from "../components/imageLink";
import LineBreak from "../components/lineBreak";
import SEO from "../components/seo";

const Index = ({ data, location }) => {
  return (
    <>
      <SEO
        title="Learn to Dance!"
        description="Expert dance lessons in a friendly atmosphere. We offer lessons for all levels of dancing experience across Bath. Learn to dance Jive, Cha Cha Cha, Waltz, Foxtrot and more!"
        keywords={[
          `bath`,
          `bristol`,
          `dance`,
          `lessons`,
          `classes`,
          `learn`,
          `dancentre`,
          `latin`,
          `kids`,
          `ballroom`,
        ]}
        location={location}
      />
      {/* <Notice /> */}
      <section
        css={css`
          h3 {
            text-align: left;
            margin-bottom: 1rem;
          }
        `}
      >
        <h2 className="invisible">News and Updates</h2>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            justify-items: center;
            gap: 1.5rem;
          `}
        >
          {/* <Card to="/reopening" fixed={data.news.sharp.fixed}>
            <h2 className="font-semibold text-xl">Latest News and Updates</h2>
            <p className="text-gray-700 text-base">
              Learn about all the latest news and updates since reopening our
              doors after the covid closure.
            </p>
          </Card> */}
          <Card to="/lessons" fixed={data.kids_medals.sharp.fixed}>
            <h2 className="font-semibold text-xl">Adult Classes</h2>
            <p>
              Improvers, Intermediate and Advanced classes at Paulton Rovers
              Club, Hillside Hall, Orchard Hall and Chippenham.
            </p>
            <ul className="list-disc list-inside pl-2 mb-2">
              <li>Ballroom, Latin-American and Sequence.</li>
              <li>
                <span className="text-highlight font-semibold">£8</span> per
                person per session.
              </li>
            </ul>
            <h2 className="font-semibold text-xl">Children's Classes</h2>
            <p>Fridays and Saturdays.</p>
          </Card>
          <Card to="/lessons" fixed={data.adult_course.sharp.fixed}>
            <h2 className="font-semibold text-xl text-left">
              New Adult Classes
            </h2>
            <p>
              New beginners classes starting Thursday 23rd January 2025,
              8.00-9.00pm. Hillside Hall, Bath BA2 3NX.
            </p>
            <p className="text-highlight font-semibold">Also</p>
            <p>
              Monday 10th February 2025, 8.00-9.00pm. Paulton Rovers Social
              Club, Paulton BS39 7RF.
            </p>
            <p>
              Both Classes pay weekly{" "}
              <span className="text-highlight font-semibold">£8.00</span> per
              session, cash preferred. Couples Only.
            </p>
          </Card>
          <Card to="/private-lessons" fixed={data.private_lessons.sharp.fixed}>
            <h3 className="font-semibold text-xl">Private Lessons</h3>
            <p>
              We offer private dance lessons to make that first dance extra
              special. We have many years of teaching experience, at all levels.
            </p>
          </Card>
        </div>
      </section>
      <div className="font-display font-bold text-4xl text-center mt-8">
        <p>
          Make <span className="text-highlight">2025</span> a Happy and Fun
          Dancing Year!
        </p>
      </div>
      <LineBreak />
      <section className="flex flex-col text-justify">
        <h1 className="invisible">Classes and Events</h1>
        <div className="flex flex-col items-center md:flex-row">
          <div className="w-full md:w-1/2 text-center md:pr-8">
            <ImageLink
              to="/lessons"
              label="Lessons"
              fluid={data.lessons.sharp.fluid}
            />
          </div>
          <div className="hidden w-full md:block md:w-1/2">
            Expert dance lessons in a friendly atmosphere. We offer dance
            classes for all levels of experience. Learn to dance Jive, Cha Cha
            Cha, Waltz, Foxtrot and more!
          </div>
        </div>
        <div className="flex flex-col items-center md:flex-row mt-3">
          <div className="w-full md:w-1/2 text-center md:pr-8">
            <ImageLink
              to="/events"
              label="Events"
              fluid={data.events.sharp.fluid}
            />
          </div>
          <div className="hidden w-full md:block md:w-1/2">
            Join us for a special events, social dances and dance medal
            qualifications.
          </div>
        </div>
      </section>
      <LineBreak />
      <section>
        <h2 className="invisible">Photo Gallery</h2>
        <Carousel />
      </section>
    </>
  );
};

export const query = graphql`
  query {
    lessons: file(relativePath: { eq: "lessons.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    events: file(relativePath: { eq: "events.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    news: file(relativePath: { eq: "lessons.jpg" }) {
      sharp: childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    events_dance: file(relativePath: { eq: "events.jpg" }) {
      sharp: childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    adult_course: file(relativePath: { eq: "adult-beginners.jpg" }) {
      sharp: childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    kids_lessons: file(relativePath: { eq: "kids-lessons.jpg" }) {
      sharp: childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    kids_medals: file(relativePath: { eq: "kids-medals.jpg" }) {
      sharp: childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    private_lessons: file(relativePath: { eq: "wedding-dance.jpg" }) {
      sharp: childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    adult_lessons: file(relativePath: { eq: "adult-lessons.jpg" }) {
      sharp: childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;

export default Index;
